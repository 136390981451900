.recipe-container{
    margin-bottom: 100px;
}


.container-1{
    display: flex;
    flex-direction: row;
}

.recipe-col-1 img{
    /* width: 250px; */
}

.recipe-col-2{
    padding: 0px 10px 10px 10px;
    margin-left: 20px;
}

.recipe-veg {
    
    float: right;
  
    border: 2px solid green;
    padding: 0px 6px;
}

.recipe-veg .fa-sharp {
    color: green;
}

.recipe-nonveg .fa-sharp{
    color: orange;
}

.rated{
    color: orange;
}

.recipe-heading-hr{
    height: 1px;
    width: 100%;
    margin-top: 0px;
}

.recipe-details{
    display: flex;
    flex-wrap: wrap;    
    border: 1px solid black;
    border-radius: 4px;
}

.recipe-video i{
    font-size: 21px;
}

.recipe-details .recipe-details-child{
    padding: 20px 20px 20px 0px;
}

.recipe-details-child{
    
    margin: 10px;
}
.recipe-details-child p{
    font-weight: bold;
}

.recipe-video i{
    padding: 5px;
}

.container-2,.container-3,.container-4{
    margin-top: 20px;
}
.ing{
    display: flex;
    flex-wrap: wrap;
}

.container-3 p{
    background-color: green;
    color: white;
    margin: 2px;
    padding: 5px;
    border-radius: 4px;
}

 @media (max-width: 992px) {

    .container-1 {
        flex-direction: column;    
    }

    .recipe-col-2 {
        
        margin-left: 0px;
    }
        

    .recipe-col-2 {
        padding: 0px;
        margin-top: 15px;
    }
     
 }

@media (max-width: 576px) {
     

}

 @media (max-width: 435px) {

    .recipe-details {
           flex-direction: column;
    }
    .recipe-col-1 img {
       max-width: 100%;
    }
    .recipe-details-child {
        margin: -10px 10px;
    }

    .recipe-veg{
        float: left;
        padding: 0px 5px;
        width: 31px;
    }
 }