.error-found {
    text-align: center;
    border: 1px dotted red;
    margin: 10px;
    background: #ffd0e7;
    color: red;
    border-radius: 4px;
}

.error-found h3 {
    margin-top: 7px;
}