.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #eed056;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.loader-div {
    text-align: -webkit-center;
}

@media (max-width: 576px) {

    .loader {
        margin-top: 36px;
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #eed056;
        width: 100px;
        height: 100px;
        animation: spin 2s linear infinite;
    }


}