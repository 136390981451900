 
 /* width */
 ::-webkit-scrollbar {
 	width: 0.625rem;
 }

 /* Track */
 ::-webkit-scrollbar-track {
 	box-shadow: inset 0 0 0.313rem grey;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
 	background: #4F709C;
 }
 
 .navbar-container{
	background: #FFFFFF;
	position: sticky;
	z-index: 1000;
	top: 0;
	box-shadow: 0px 15px 10px -18px #111;
	margin-bottom: 40px;
 }

 .page-heading{
    text-align: center;
}

.page-content{
    text-align: justify;
}

h1,h2,h3,h4,h5{
font-family: 'Roboto Slab', serif;
}

p{
	font-family: 'Poppins', sans-serif;
}

a{
	font-family: 'Roboto Slab', serif;
}