.no-match-found {
    text-align: center;
    border: 1px dotted limegreen;
    margin: 10px;
    background: #edf2f9;
    border-radius: 4px;
}

.no-match-found h3 {
    margin-top: 7px;
}