
.custom-navbar-main-div{
    margin-bottom: -22px;
}

.header-img{
height: 100px;
}

.navbar-toggler{
 outline: none !important;
  box-shadow: none !important;
}

.navbar-nav li a:hover{
color: #4F709C;
font-weight: bold;
}

.navbar-nav .active{
color: #4F709C !important;
  font-weight: bold;
}

.custom-nav-align{
    justify-content: right;
}

.nav-item{
    font-size: 18px;
    padding: 11px;
}


 @media (max-width: 992px) {
    .header-img {
       height: 92px;
    }

 }

 @media (max-width: 576px) {
    .nav-item {
    font-size: 15px;
    padding: 0px;
    }
 
}

 @media (max-width: 435px) {}

 