.veg{
    position: inherit;
    float: right;
    top: -50px;
    border: 2px solid green;
    padding: 0px 6px;
}

.nonveg{
    position: inherit;
    float: right;
    top: -50px;
    border: 2px solid orange;
    padding: 0px 6px;
}

.veg .fa-sharp{
    color: green;
}

.nonveg .fa-sharp{
    color: orange;
}

.recipe-desc{
    width: 90ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}